
import React from 'react';
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) => {
  const directions = ['up', 'left', 'right', 'down'];
  const direction = directions[Math.floor(Math.random()*directions.length)] as ('left' | 'right' | 'up' | 'down');
  return <Slide direction={direction} ref={ref} {...props} />;
});

interface InfoDialogProps {
  title: string | undefined;
  mapUrl: string | undefined;
  isOpen: boolean;
  onClose: () => void;
}

export const InfoDialog = (props: InfoDialogProps) => {
  return (
    <Dialog 
      onClose={props.onClose}
      open={props.isOpen}
      fullWidth={true}
      TransitionComponent={Transition}
    >

      <AppBar sx={{ position: 'relative' }} style={{padding: 0}}>
        <Toolbar style={{padding: 0}}>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            {props.title}
          </Typography>
          <IconButton
            edge='start'
            color='inherit'
            onClick={props.onClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent style={{overflow: 'hidden'}}>
        <iframe 
          src={props.mapUrl}
          width={'100%'}
          height={450} 
          style={{border: 0}}
          loading={'lazy'}
        />
      </DialogContent>

    </Dialog>
  );
};
