
import React from 'react';
import { Loader } from '../app/Loader';
import { useRSVPForm } from '../../hooks/useRSVPForm';
import { OAuthRSVPLoginObject } from '../rsvp/OAuthLoginPanel';
import { RSVPFormField } from '../../domain/RSVPFormField';
import { RSVPCompanionsScreen } from './rsvp-screens/RSVPCompanionsScreen';
import { WillYouAttendScreen } from './rsvp-screens/WillYouAttendScreen';
import { WhoAreYouScreen } from './rsvp-screens/WhoAreYouScreen';
import { MainPersonAllergiesScreen } from './rsvp-screens/MainPersonAllergiesScreen';
import { MethodOfRSVPScreen } from './rsvp-screens/MethodOfRSVPScreen';

export interface FormPanelProps {
    currentStep: number;
    maximumStep: number;
    onLoginOAuth: (loginObject: OAuthRSVPLoginObject) => void;
    getFormValue: <T>(id: RSVPFormField) => T;
    onChangeEvent: (id: RSVPFormField) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChange: <T extends object | string>(id: RSVPFormField, value: T) => void;
    onPrevStep: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onNextStep: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const RSVPForm = () => {
    const {
        isLoading,
        isSubmitted,
        displayName,
        onChangeEvent,
        onChange,
        onSubmit,
        getFormValue,
        onLoginOAuth,
    } = useRSVPForm();

    const [currentStep, setCurrentStep] = React.useState(0);
    const stepsComponent: React.FunctionComponent<FormPanelProps>[] = [
        MethodOfRSVPScreen, WhoAreYouScreen, WillYouAttendScreen, MainPersonAllergiesScreen, RSVPCompanionsScreen
    ];

    const Component = stepsComponent[currentStep];

    const onWrapPreventDefault = (func: Function) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        func();
    };

    return (
        <>
            {displayName && <h2 style={{textAlign: 'center', marginTop: 0}}>{`Special invitation for ${displayName}!`}</h2>}
            <div style={{margin: 'auto'}}>
                {
                    isSubmitted ?
                        <div style={{textAlign: 'center'}}>
                            <div style={{textAlign: 'center', fontSize: 36}}>{'✅'}</div>
                            <div style={{textAlign: 'center', margin: 10}}>{'Your RSVP has been sent! Please check your e-mail for your confirmation.'}</div>
                        </div>
                        :
                        <Component
                            getFormValue={getFormValue}
                            onChangeEvent={onChangeEvent}
                            onLoginOAuth={(loginObject) => {
                                setCurrentStep(1);
                                onLoginOAuth(loginObject);
                            }}
                            onChange={onChange}
                            currentStep={currentStep}
                            maximumStep={stepsComponent.length - 1}
                            onPrevStep={onWrapPreventDefault(() => setCurrentStep(currentStep - 1))}
                            onNextStep={onWrapPreventDefault(() => setCurrentStep(currentStep + 1))}
                            onSubmit={onWrapPreventDefault(onSubmit)}
                        />
                }

                {
                    isLoading && <Loader/>
                }
            </div>
        </>
    );
};

