import React from 'react';
import { LargerPhotoDialog } from './LargerPhotoDialog';

interface PhotosProps {
    photoBucketPrefix: string;
    thumbnailPrefix: string;
    maxPhotos: number;
    viewerTitle: string;
}

export const Photos = (props: PhotosProps) => {
    const [openImgSrc, setOpenImgSrc] = React.useState<string | null>(null);
    const onClose = () => {
        setOpenImgSrc(null);
    };
  
    const onImageClick = (openImgSrc: string) => () => {
        setOpenImgSrc(openImgSrc.replace(props.thumbnailPrefix, props.photoBucketPrefix));
    };

    const photoNames = React.useMemo(() => {
        const maxPhotos = props.maxPhotos;
        return Array(maxPhotos).fill(0).map((_, i) => {
            const num = i + 1;
            if ( num < 10 ) {
                return `${props.thumbnailPrefix}0${num}.jpg`;
            } else {
                return `${props.thumbnailPrefix}${num}.jpg`;
            }
        });        
    }, []);

    return (
        <section>
            <div style={{textAlign: 'center'}}>
                {
                    photoNames.map(src => (
                        <img 
                            src={src} 
                            width={'30%'} 
                            height={200} 
                            style={{
                                objectFit: 'cover', 
                                marginRight: 5, 
                                marginBottom: 2,
                                cursor: 'pointer',
                            }}
                            key={src}
                            onClick={onImageClick(src)}
                        />
                    ))
                }
            </div>
            {
                openImgSrc && (
                    <LargerPhotoDialog
                        openImgSrc={openImgSrc}
                        onClose={onClose}
                        title={props.viewerTitle}
                    />
                )
            }
        </section>
    );
};