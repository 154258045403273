import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
    buttonsContainer: {
        textAlign: 'center',
    },
    buttonsWrapper: {
        margin: '0 5px', 
        display: 'inline-block',
    }
}));

interface NavButtonsPanelProps {
    isPrevDisabled?: boolean;
    isNextDisabled?: boolean;
    isSubmitDisabled?: boolean;
    hideSubmit?: boolean;
    currentStep: number;
    maximumStep: number;
    onPrevStep: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onNextStep: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const NavButtonsPanel = (props: NavButtonsPanelProps) => {
    const classes = useStyles();
    const disabledBtnStyles = {
        backgroundColor: '#5c5c5e',
        cursor: 'not-allowed',
    };
    return (
        <div className={classes.buttonsContainer}>
            {
                props.currentStep !== 0 && (
                    <div className={classes.buttonsWrapper}>
                        <button
                            disabled={props.isPrevDisabled}
                            onClick={props.onPrevStep}
                            style={props.isPrevDisabled ? disabledBtnStyles : {}}
                        >
                            {'Previous'}
                        </button>
                    </div>
                )
            }
            {
                props.currentStep !== props.maximumStep && (
                    <div className={classes.buttonsWrapper}>
                        <button
                            disabled={props.isNextDisabled}
                            onClick={props.onNextStep}
                            style={props.isNextDisabled ? disabledBtnStyles : {}}
                        >
                            {'Next'}
                        </button>
                    </div>
                )
            }
            {
                props.currentStep === props.maximumStep && !props.hideSubmit &&(
                    <div className={classes.buttonsWrapper}>
                        <button
                            disabled={props.isSubmitDisabled}
                            onClick={props.onSubmit}
                            style={props.isSubmitDisabled ? disabledBtnStyles : {}}
                        >
                            {'Submit RSVP!!'}
                        </button>
                    </div>
                )
            }
        </div>
    );
};