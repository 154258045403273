
import React from 'react';
import { EventDescription } from './EventDescription';
import { WeddingEvent } from '../../domain/WeddingEvent';
import { InfoDialog } from './InfoDialog';

const AboutWedding: React.FC = () => {
  const [openDialog, setOpenDialog] = React.useState<{title: string; mapUrl: string;} |null>(null);
  const onClose = () => {
    setOpenDialog(null);
  };

  const onLinkClick = (title: string, mapUrl: string) => () => {
    setOpenDialog({
      title,
      mapUrl,
    })
  };
  return (
    <section className={'about-section'}>
      <h3>{'Timeline'}</h3>
      <p>Our wedding will be a celebration of love and togetherness. We look forward to creating unforgettable memories with you in one of the most beautiful places in the world.</p>
        <EventDescription
          id={WeddingEvent.welcomeDinner}
          date={'May 24, 2025'}
          location={'Central Boracay'}
          imgSrc={'/images/about-wedding/central-boracay.png'}
          description={'Optional - evening welcome dinner'}
          onLinkClick={onLinkClick(
            'Central Boracay',
            `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31226.547230562493!2d121.90901441302033!3d11.952443049717512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a53c22c3a8466b%3A0x443e5166ae9cf4b2!2sWhite%20Beach!5e0!3m2!1sen!2sus!4v1733019584335!5m2!1sen!2sus`,
          )}
        />
        <EventDescription
          id={WeddingEvent.wedding}
          date={'May 25, 2025'}
          location={'Shangri-La Boracay'}
          imgSrc={'/images/about-wedding/shangrila.png'}
          description={'starts 3 pm Wedding, Reception'}
          isLightTheme
          onLinkClick={onLinkClick(
            'Shangri-La Boracay',
            `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124890.02195375522!2d121.76203324335938!3d11.987424900000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a53c79fb9011c1%3A0xdcecb532f50053e5!2sShangri-La%20Boracay!5e0!3m2!1sen!2sus!4v1733019646708!5m2!1sen!2sus`,
          )}
        />
        <EventDescription
          id={WeddingEvent.afterParties}
          date={'May 27, 2025'}
          location={'Bonifacio Global City, Metro Manila'}
          imgSrc={'/images/about-wedding/bgc.png'}
          description={'Optional - after parties'}
          onLinkClick={onLinkClick(
            'Bonifacio Global City, Metro Manila',
            `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30895.8222304443!2d121.04190949999999!3d14.54326345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c8f3fa2994af%3A0x89c988af4760e40a!2sFort%20Bonifacio%2C%20Taguig%2C%20Metro%20Manila%2C%20Philippines!5e0!3m2!1sen!2sus!4v1733019104121!5m2!1sen!2sus`,
          )}
        />
        <InfoDialog
          isOpen={!!openDialog}
          mapUrl={openDialog?.mapUrl}
          title={openDialog?.title}
          onClose={onClose}
        />
    </section>
  );
};

export default AboutWedding;
