import { IResolveParams } from 'reactjs-social-login';
import { OAuthRSVPLoginObject } from '../components/rsvp/OAuthLoginPanel';

export const useOAuthLoginPanel = (onLoginOAuth: (loginObject: OAuthRSVPLoginObject) => void) => {
    const standardizeResponse = (provider: string, firstName: string, lastName: string, accessToken: string, email: string | null, pictureUrl: string) => {
        return {
            provider,
            firstName,
            lastName,
            accessToken,
            email,
            pictureUrl,
        };
    };

    const onFacebookResolve = ({data, provider}: IResolveParams) => {
        if (data) {
            const {
                accessToken,
                email,
                first_name,
                last_name,
                picture,
                userID,
            } = data;
            const {url} = picture.data;
            onLoginOAuth(standardizeResponse(provider, first_name, last_name, accessToken, email, url));
        } else {
            throw new Error('No data retrieved for Facebook login.');
        }
    };

    const onGoogleLogin = ({data, provider}: IResolveParams) => {
        if (data) {
            const {
                accessToken,
                family_name,
                given_name,
                picture
            } = data;
            onLoginOAuth(standardizeResponse(provider, given_name, family_name, accessToken, null, picture));
        } else {
            throw new Error('No data retrieved for Google login.');
        }
    };

    return {
        onFacebookResolve,
        onGoogleLogin,
    };
};