import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface QuestionItemProps {
    question: string;
    answer: string;
}

export const QuestionItem = (props: QuestionItemProps) => {
    return (
        <Accordion style={{
            backgroundColor: 'transparent', 
            border: 0,
            '--Paper-shadow': 'none',
            marginBottom: 10,
        } as React.CSSProperties}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{color: '#fff'}}/>}
                style={{
                    backgroundColor: '#2c2c2e',
                    color: '#fff',
                    borderRadius: 20,
                    border: 0,
                }}
            >
                {props.question}
            </AccordionSummary>
            <AccordionDetails
                style={{
                    color: '#fff',
                }}
            >
                {props.answer}
            </AccordionDetails>
        </Accordion>
    );
};
