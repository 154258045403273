
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import NavBar from './NavBar';
import Header from './Header';
import { AppRoutes } from './AppRoutes';

const App: React.FC = () => {
  return (
    <Router>
      <div className={'main-content'}>
        <NavBar />
        <Header />
        <AppRoutes />
      </div>
    </Router>
  );
};

export default App;
