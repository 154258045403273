import React from 'react';
import { Photos } from './Photos';

export const ManilaProposalPhotos = () => {
    return (
        <div>
            <h3>{'Manila Proposal'}</h3>
            <div className="video-container" style={{ marginTop: '20px', textAlign: 'center' }}>
                <iframe 
                    style={{width: 560, height: 315, maxWidth: '100%'}}
                    src="https://www.youtube.com/embed/uaydl94umjU?si=GLvVOaoYCc8q6baw" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen>
                </iframe>
            </div>
            <Photos
                photoBucketPrefix={'https://dbszh5idol72h.cloudfront.net/'}
                thumbnailPrefix={'https://d3and355vidtmd.cloudfront.net/'}
                maxPhotos={65}
                viewerTitle={'Manila Proposal'}
            />
        </div>
    );
};