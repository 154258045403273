import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import FlightIcon from '@mui/icons-material/Flight';
import ChurchIcon from '@mui/icons-material/Church';
import MicIcon from '@mui/icons-material/Mic';
import LandscapeIcon from '@mui/icons-material/Landscape';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import EggAltIcon from '@mui/icons-material/EggAlt';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import SailingIcon from '@mui/icons-material/Sailing';
import DiamondIcon from '@mui/icons-material/Diamond';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import HttpsIcon from '@mui/icons-material/Https';
import StadiumIcon from '@mui/icons-material/Stadium';
import MasksIcon from '@mui/icons-material/Masks';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CasinoIcon from '@mui/icons-material/Casino';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import HomeIcon from '@mui/icons-material/Home';
import MovingIcon from '@mui/icons-material/Moving';
import CakeIcon from '@mui/icons-material/Cake';

export interface TimelineConfig {
    date: string;
    Icon: React.JSXElementConstructor<{}>;
    title: string;
    description: string;
}

export const CoupleTimelineConfig: TimelineConfig[] = [
    {
        date: 'Aug 2016',
        Icon: PhoneIphoneIcon,
        title: 'Virtual meet',
        description: 'dating apps before it was cool',
    },
    {
        date: 'Aug 2016',
        Icon: HandshakeIcon,
        title: 'We met',
        description: 'We met at a Jollibee!',
    },
    {
        date: 'Sep 2016',
        Icon: EggAltIcon,
        title: 'Shinsen',
        description: 'First time we dated at our frequent fav Japanese restaurant',
    },
    {
        date: 'Oct 2016',
        Icon: SupervisorAccountIcon,
        title: 'Met her parents',
        description: 'We first met at church in her hometown',
    },
    {
        date: 'Dec 2016',
        Icon: SportsTennisIcon,
        title: 'First Badminton',
        description: 'We played doubles',
    },
    {
        date: 'Feb 2017',
        Icon: LandscapeIcon,
        title: 'Climbed our first mountain',
        description: 'It was Mount Batulao in Nasugbu, Philippines',
    },
    {
        date: 'Jun 2017',
        Icon: MicIcon,
        title: 'First concert together!',
        description: 'Hit me baby one more time @ MOA Arena',
    },
    {
        date: 'Jul 2017',
        Icon: DirectionsCarIcon,
        title: 'First road trip together',
        description: 'We went to Masungi Geopark',
    },
    {
        date: 'Aug 2017',
        Icon: HeadphonesIcon,
        title: 'First gun date together',
        description: 'Patrick got all bullseye',
    },
    {
        date: 'Jan 2018',
        Icon: SailingIcon,
        title: 'Boracay',
        description: 'First time we went to Boracay and we loved it',
    },
    {
        date: 'Jan 2018',
        Icon: FlightIcon,
        title: 'First airplane trip with her family',
        description: 'We visited her cousins and aunts/uncles in the Central Philippines',
    },

    {
        date: 'Aug 2018',
        Icon: FlightIcon,
        title: '1st int\'l adventure!',
        description: 'We flew to Thailand / Cambodia / Vietnam together!',
    },
    {
        date: 'Apr 2019',
        Icon: Diversity3Icon,
        title: 'Abejar & Zerna families meet',
        description: 'Met a few times in BGC and her family\'s house',
    },
    {
        date: 'Dec 2019',
        Icon: AcUnitIcon,
        title: 'Snow together',
        description: 'Our first snow together was in Aomori station Japan and then Hokkaido',
    },
    {
        date: 'Jan 2020',
        Icon: HttpsIcon,
        title: 'Lock of Love',
        description: 'We left a lock of love in Fukuoka'
    },
    {
        date: 'Aug/Sep 2021',
        Icon: CakeIcon,
        title: 'Birthdays together',
        description: 'First time we spent birthdays together in the same year',
    },
    {
        date: 'Dec 2021',
        Icon: FlightIcon,
        title: 'Europe trip',
        description: 'So many required COVID tests to go to the U.K. and a canceled France trip',
    },
    {
        date: 'Jan 2022',
        Icon: MasksIcon,
        title: 'Quarantine together',
        description: 'We spent 10 days in a hotel room eating their food',
    },
    {
        date: 'Jun 2022',
        Icon: VaccinesIcon,
        title: 'COVID together',
        description: 'We got COVID in Boracay but symptoms came when separated',
    },
    {
        date: 'Dec 2022',
        Icon: MonetizationOnIcon,
        title: 'Dates in the USA',
        description: 'Spent time mostly in New York, but we also visited D.C. and Philly',
    },
    {
        date: 'Mar 2023',
        Icon: DiamondIcon,
        title: 'Proposal',
        description: 'Proposed on top of a rooftop garden with family in front of the Manila skyline',
    },
    {
        date: 'Sep 2023',
        Icon: StadiumIcon,
        title: '1st Twice concert',
        description: 'Her bias is Jihyo'
    },
    {
        date: 'Feb 2024',
        Icon: ChurchIcon,
        title: 'NY City Hall Wedding',
        description: 'We got married in Brooklyn',
    },
    {
        date: 'Mar 2024',
        Icon: CasinoIcon,
        title: 'Honeymoon in Las Vegas',
        description: 'Grand Canyon and selfie with SaiDaTzu',
    },
    {
        date: 'May 2024',
        Icon: MovingIcon,
        title: 'Permanent Residency',
        description: '58 days was a fast one for her'
    },
    {
        date: 'Oct 2024',
        Icon: HomeIcon,
        title: 'Closed on our new home',
        description: 'Queens is the best borough',
    },
    {
        date: 'May 2025',
        Icon: BeachAccessIcon,
        title: 'Wedding in Boracay',
        description: 'See you there',
    }
];