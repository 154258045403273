
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  panel: {
    margin: 20,
    border: `1px solid #ffffff`,
    borderRadius: 20,
    display: 'flex',
  },
  lightTheme: {
    backgroundColor: '#efefef',
    color: '#3c3c3e',
  },
  location: {
    fontStyle: 'italic',
    fontSize: 12,
  },
  description: {
    margin: 10,
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  imgPanel: {
    display: 'flex',
    overflow: 'hidden',
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  subpanel: {
    padding: '10px 20px',
  }
}));

interface EventDescriptionProps<T> {
    id: T;
    date: string;
    location: string;
    imgSrc: string;
    description: string | React.ReactNode;
    isLightTheme?: boolean;
    onLinkClick: (id: T) => void;
}

export const EventDescription = <T extends string>(props: EventDescriptionProps<T>) => {
  const classes = useStyles();
  const onClickLocation = () => props.onLinkClick(props.id);
  return (
    <div className={`${classes.panel} ${props.isLightTheme ? classes.lightTheme : ''}`}>
      <div className={classes.imgPanel}>
        <img 
          src={props.imgSrc}
          width={250}
        />
      </div>
      <div className={classes.subpanel}>
        <div className={classes.title}>{props.date}</div>
        <div className={classes.location}>
          {`Location: `}
          <Link
            href={'#'}
            onClick={onClickLocation}
          >
            {props.location}
          </Link>
        </div>
        <div className={classes.description}>{props.description}</div>
      </div>
    </div>
  );
};