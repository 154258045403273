import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
    image: {
        width: '50%',
        borderRadius: 20,
    }
}));

export const DressCode = () => {
    const classes = useStyles();
    return (
        <section>
            <h3>{'Dress Code'}</h3>
            <p>
                {'Ladies: Pastel floral, floor length (no whites please)'}
            </p>
            <p style={{textAlign: 'center'}}>
                <img src={'/images/dress-code/ladies.png'} className={classes.image}/>
            </p>
            <p>
                {'Gentlemen: Pastel colors, button up long sleeves, pants (no suits please) inspo of button down below:'}
            </p>
            <p style={{textAlign: 'center'}}>
                <img src={'/images/dress-code/gentlemen.png'} className={classes.image}/>
            </p>

            <p>
                {'Color Palette: Colorful Floral'}
            </p>
            <p style={{textAlign: 'center'}}>
                <img src={'/images/dress-code/palette.png'} className={classes.image}/>
            </p>
        </section>
    );
};