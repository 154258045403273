
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export const Loader = () => {
    return (
        <div 
            style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                zIndex: 10,
                opacity: 0.5,
                background: '#000',
            }}
        >
            <div 
                style={{
                    textAlign: 'center',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                }}
            >
                <CircularProgress />
            </div>
        </div>
    );
};