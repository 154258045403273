
import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { AboutCouple } from '../couple/AboutCouple';
import AboutWedding from '../wedding/AboutWedding';
import RSVP from '../rsvp/RSVP';
import { RouteKeys } from '../../domain/RouteKeys';
import { Details } from '../details/Details';
import { LocalStorageKeys } from '../../domain/LocalStorageKeys';

export const AppRoutes = () => {
  const location = useLocation();
  const params = new URL(window.location.href).searchParams;
  const paramsGuestName = params.get('invited');

  const getUser = () => {
    return paramsGuestName ?? localStorage.getItem(LocalStorageKeys.pdaBoracayInvited);
  };

  React.useEffect(() => {
    if ( paramsGuestName !== null ) {
      localStorage.setItem(LocalStorageKeys.pdaBoracayInvited, paramsGuestName);
    }
  }, []);

  React.useEffect(() => {
    const pathname = location.pathname;
    const twoSlashesRegex = /^(?:[^\/]*\/){2}[^\/]*$/;

    if (pathname === '/' || pathname.includes(RouteKeys.RSVP) || pathname.includes(RouteKeys.EVENTS) || pathname.match(twoSlashesRegex)){
      const request = {
        pathname: pathname, 
        mounted: new Date().toISOString(),
        userAgent: navigator.userAgent,
        language: navigator.language,
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
        outerHeight: window.outerHeight,
        outerWidth: window.outerWidth,
        paramsGuestName: getUser(),
      };
      fetch('https://bk1or2iywa.execute-api.us-east-1.amazonaws.com/prod/survey', {
        method: 'PUT',
        body: JSON.stringify(request),
      });
    }
  }, [location]);

  const makeNotExact = (routeKey: string) => {
    return `${routeKey}/*`;
  };

  return (
    <Routes>
      <Route path={RouteKeys.RSVP} element={<RSVP />} />
      <Route path={makeNotExact(RouteKeys.COUPLE)} element={<AboutCouple />} />
      <Route path={RouteKeys.EVENTS} element={<AboutWedding />} />
      <Route path={makeNotExact(RouteKeys.DETAILS)} element={<Details/>} />
      <Route path={'*'} element={<Navigate to={`${RouteKeys.RSVP}?invited=${getUser()}`}/>}/>
    </Routes>
  );
};