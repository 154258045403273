import React from 'react';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';
import { useOAuthLoginPanel } from '../../hooks/useOAuthLoginPanel';

export interface OAuthRSVPLoginObject {
    provider: string;
    firstName: string;
    lastName: string;
    accessToken: string;
    email: string | null;
    pictureUrl: string;
}

interface OAuthLoginPanelProps {
    onLoginOAuth: (loginObject: OAuthRSVPLoginObject) => void;
}

export const OAuthLoginPanel = (props: OAuthLoginPanelProps) => {
    const {
        onFacebookResolve,
        onGoogleLogin,
    } = useOAuthLoginPanel(props.onLoginOAuth);

    return (
        <div style={{textAlign: 'center'}}>
            <LoginSocialFacebook
                appId={'1098400278545849'}
                onResolve={onFacebookResolve}
                onReject={(error) => {
                    console.log(error);
                }}
            >
                <FacebookLoginButton
                    text={'RSVP with Facebook'}
                    style={{fontSize: 12}}
                    iconSize={20}
                    size={'40px'}
                />
            </LoginSocialFacebook>
            {'or'}
            <LoginSocialGoogle
                client_id={'858267108966-2ljh6aj4232hc4r44p143iiu2d3du009.apps.googleusercontent.com'}
                onResolve={onGoogleLogin}
                onReject={(error) => {
                    console.log(error);
                }}
            >
                <GoogleLoginButton
                    text={'RSVP with Google'}
                    style={{fontSize: 12}}
                    iconSize={20}
                    size={'40px'}
                />
            </LoginSocialGoogle>
        </div>
    );
};