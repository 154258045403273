import React from 'react';
import { Photos } from './Photos';

export const NYCWeddingPhotos = () => {
    return (
        <div>
            <h3>{'NYC Wedding'}</h3>
            <Photos
                photoBucketPrefix={'https://d2ib32vylxpw0d.cloudfront.net/'}
                thumbnailPrefix={'https://dz1ijsumsa1uq.cloudfront.net/'}
                maxPhotos={71}
                viewerTitle={'NYC Wedding'}
            />
            <div style={{ textAlign: 'center' }}>
                <iframe 
                    style={{width: 405, height: 720, maxWidth: '100%'}}
                    src="https://www.youtube.com/embed/h0Lcc4TIBGs?si=Jn0ogqWfe_vDKX_e" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                />
            </div>
        </div>
    );
};