
import { makeStyles } from '@mui/styles';
import React from 'react';
import { YesNoRadio } from '../YesNoRadio';
import { RSVPFormField } from '../../../domain/RSVPFormField';
import { NavButtonsPanel } from '../NavButtonsPanel';
import { FormPanelProps } from '../RSVPForm';
import { YesNo } from '../../../domain/YesNo';

const useStyles = makeStyles(() => ({
    label: {
        display: 'block', 
        marginBottom: '10px',
        textAlign: 'center',
    },
    textfield: { 
        marginLeft: '10px', 
        padding: '5px', 
        width: '-webkit-fill-available',
    },
}));

export const MainPersonAllergiesScreen = (props: FormPanelProps) => {
    const classes = useStyles();

    return (
        <div>
            <YesNoRadio
                id={RSVPFormField.hasFoodRestrictions}
                label={'Do you have any food restrictions or allergies?'}
                onChange={props.onChange}
                value={props.getFormValue<YesNo>(RSVPFormField.hasFoodRestrictions)}
            />
            {
                props.getFormValue<string>(RSVPFormField.hasFoodRestrictions) === YesNo.YES && (
                    <label className={classes.label}>
                        {'What are you food restrictions or allergies?'}
                        <input 
                            type={'text'} 
                            name={RSVPFormField.foodRestrictionsText} 
                            className={classes.textfield} 
                            value={props.getFormValue<string>(RSVPFormField.foodRestrictionsText)}
                            onChange={props.onChangeEvent(RSVPFormField.foodRestrictionsText)} 
                        />
                    </label>
                )
            }
            <NavButtonsPanel
                currentStep={props.currentStep}
                maximumStep={props.maximumStep}
                onPrevStep={props.onPrevStep}
                onNextStep={props.onNextStep}
                onSubmit={props.onSubmit}
            />
        </div>
    );
};