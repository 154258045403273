import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AboutBoracay from './location/AboutBoracay';
import { Questions } from './questions/Questions';
import { DressCode } from './dress/DressCode';
import { DetailVideos } from './videos/DetailVideos';
import { Route, Routes, useParams, useNavigate, Navigate } from 'react-router-dom';

enum Detail {
    AboutBoracay = 'about-boracay',
    Questions = 'questions',
    DressCode = 'dress-code',
    Videos = 'videos',
}

const DetailsConfig = [
    {
        title: 'Boracay',
        value: Detail.AboutBoracay,
        component: AboutBoracay,
    },
    {
        title: 'FAQs',
        value: Detail.Questions,
        component: Questions,
    },
    {
        title: 'Dress',
        value: Detail.DressCode,
        component: DressCode,
    },
    {
        title: 'Videos',
        value: Detail.Videos,
        component: DetailVideos,
    }
];

export const Details = () => {
    const navigate = useNavigate();
    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        currentDetailScreen: Detail | undefined,
    ) => {
        if (currentDetailScreen) {
            navigate(currentDetailScreen);
        }
    };
    const params = useParams();
    const screen = params['*'];
    return (
        <div>
            <div style={{
                textAlign: 'center',
            }}>
                <ToggleButtonGroup
                    color={'primary'}
                    value={screen}
                    exclusive
                    onChange={handleChange}
                    style={{backgroundColor: '#ffffff'}}
                >
                    {
                        DetailsConfig.map(detail => <ToggleButton value={detail.value} key={detail.value}>{detail.title}</ToggleButton>)
                    }
                </ToggleButtonGroup>
            </div>
            <Routes>
                <Route path={Detail.AboutBoracay} element={<AboutBoracay/>}/>
                <Route path={Detail.Questions} element={<Questions/>}/>
                <Route path={Detail.DressCode} element={<DressCode/>}/>
                <Route path={Detail.Videos} element={<DetailVideos/>}/>
                <Route path={'*'} element={<Navigate to={Detail.AboutBoracay}/>}/>
            </Routes>
        </div>
    );
};