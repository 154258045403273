import { CouplePage } from '../../domain/CouplePage';
import { CoupleTimeline } from './CoupleTimeline';
import { DetailStory } from './DetailStory';
import { ManilaProposalPhotos } from './ManilaProposalPhotos';
import { NYCWeddingPhotos } from './NYCWeddingPhotos';

export const AboutCoupleConfig = [
    {
        title: 'Story',
        value: CouplePage.Story,
        component: DetailStory,
    },
    {
        title: 'Timeline',
        value: CouplePage.Timeline,
        component: CoupleTimeline,
    },
    {
        title: 'NY Vows',
        value: CouplePage.Photos,
        component: NYCWeddingPhotos,
    },
    {
        title: 'Engage',
        value: CouplePage.Proposal,
        component: ManilaProposalPhotos,
    }
];
