
import React from 'react';
import { NavBarConfig } from '../../domain/NavBarConfig';
import { NavLink, NavLinkRenderProps } from 'react-router-dom';

const navLinkStyle = ({ isActive }: NavLinkRenderProps) => ({
  textDecoration: 'none',
  display: 'block',
  padding: '10px',
  textAlign: 'left' as 'left',
  backgroundColor: isActive ? '#444' : 'transparent',
  color: '#fff'
});

interface NavItemProps {
  path: string;
  icon: string;
  label: string;
}

const NavItem = (props: NavItemProps) => {
  return (
    <li>
      <NavLink 
        to={props.path} 
        style={navLinkStyle}
      >
        <span role={'img'} aria-label={'envelope'} style={{marginRight: 10}}>{props.icon}</span> 
        <span>{props.label}</span>
      </NavLink>
    </li>
  );
};

const NavBar: React.FC = () => {
  return (
    <nav className={'nav-bar'}>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {
          NavBarConfig.map(item => (
            <NavItem 
              path={item.path} 
              icon={item.icon} 
              label={item.label}
              key={item.label}
            />
          ))
        }
      </ul>
    </nav>
  );
};

export default NavBar;
