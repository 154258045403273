import React from 'react';

export const DetailVideos = () => {
    return (
        <section>
            <h3>{'Detail Videos'}</h3>
            <p>
                {'Dianne shows you how to commute on your own from Caticlan Airport to Shangri-La Boracay Resort.'}
            </p>
            <div className="video-container" style={{ marginTop: '20px', textAlign: 'center' }}>
                <iframe 
                    style={{width: 560, height: 315, maxWidth: '100%'}}
                    src="https://www.youtube.com/embed/SuvJab2i3hU" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen>
                </iframe>
            </div>
            <p>
                {'Patrick talks about getting to Boracay Island, getting around Boracay Island, and he also include tips for spending your time around the island. I walk around White Sand Beach to give people an understanding how Boracay looks like during the day. This video was taken early July 2024.'}
            </p>
            <div style={{ textAlign: 'center' }}>
                <iframe 
                    style={{width: 405, height: 720, maxWidth: '100%'}}
                    src="https://www.youtube.com/embed/pBdLXvpM_6g?si=_VGF5Z8oM9N9qiYL" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                />
            </div>
        </section>
    );
}; 