
import { YesNo } from '../../domain/YesNo';
import { RSVPFormField } from '../../domain/RSVPFormField';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useRadioStyles = makeStyles(() => ({
    label: {
      display: 'block', 
      marginBottom: '10px',
    },
    attendingRadio: {
        display: 'inline-block',
        width: 'auto',
    }
}));

interface YesNoRadio {
    id: RSVPFormField;
    label: string;
    value: string;
    onChange: (id: RSVPFormField, value: string) => void;
}

export const YesNoRadio = (props: YesNoRadio) => {
    const classes = useRadioStyles();
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(props.id, event.target.value);
    };
    return (
        <label className={classes.label}>
            <div style={{textAlign: 'center'}}>{props.label}</div>
            <div style={{textAlign: 'center'}}>
                <input 
                    type={'radio'} 
                    name={props.id} 
                    value={YesNo.YES} 
                    className={classes.attendingRadio} 
                    onChange={onChange}
                    checked={props.value === YesNo.YES}
                /> 
                {'Yes '}
                <input 
                    type={'radio'} 
                    name={props.id} 
                    value={YesNo.NO} 
                    className={classes.attendingRadio} 
                    onChange={onChange}
                    checked={props.value === YesNo.NO}
                /> 
                {'No '}
            </div>
        </label>
    );
};