
import React from 'react';
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { Loader } from '../app/Loader';

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) => {
  const directions = ['up', 'left', 'right', 'down'];
  const direction = directions[Math.floor(Math.random()*directions.length)] as ('left' | 'right' | 'up' | 'down');
  return <Slide direction={direction} ref={ref} {...props} />;
});

interface InfoDialogProps {
    title: string;
    openImgSrc: string | null;
    onClose: () => void;
}

export const LargerPhotoDialog = (props: InfoDialogProps) => {
    const [isLoading, setIsLoading] = React.useState(true);

    return (
        <Dialog 
            onClose={props.onClose}
            open={!!props.openImgSrc}
            maxWidth={false}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} style={{padding: 0 }}>
                <Toolbar style={{padding: 0}}>
                <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                    {props.title}
                </Typography>
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={props.onClose}
                    aria-label='close'
                >
                    <CloseIcon />
                </IconButton>
                </Toolbar>
            </AppBar>

            <DialogContent style={{textAlign: 'center', backgroundColor: '#1c1c1e'}}>
                <img 
                    src={`${props.openImgSrc}`} 
                    style={{
                        height: '75%', 
                        width: '75%'
                    }}
                    onLoad={() => {
                        setIsLoading(false);
                    }}
                    onError={() => {
                        setIsLoading(false);
                    }}
                />
            </DialogContent>
            {isLoading && <Loader/>}
        </Dialog>
    );
};