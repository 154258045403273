import React, { useState, useEffect } from 'react';

interface CountdownProps {
    onInterval: () => void;
}

const calculateTimeLeft = () => {
    const weddingDate = new Date('May 25, 2025 16:00:00 GMT+0800').getTime();
    const now = new Date().getTime();
    const timeLeft = weddingDate - now;
  
    return {
      months: Math.floor(timeLeft / (1000 * 60 * 60 * 24 * 30)),
      days: Math.floor((timeLeft % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)),
      hours: Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((timeLeft % (1000 * 60)) / 1000)
    };
  };
  
  
export const Countdown = (props: CountdownProps) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  
    useEffect(() => {
      const interval = setInterval(props.onInterval, 3000); // Change image every 3 seconds
  
      const countdownInterval = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000); // Update countdown every second
  
      return () => {
        clearInterval(interval);
        clearInterval(countdownInterval);
      };
    }, []);
    return (
      <>
        <div className='countdown'>
          <h4>Countdown to Our Wedding:</h4>
          <div className='countdown-timer' style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexWrap: 'nowrap',
            gap: '10px',
            fontSize: '1.4em', // Slightly smaller default font size for larger screens
            overflowX: 'auto'
          }}>
            <div className='countdown-item'>
              <span>{timeLeft.months}</span> Months
            </div>
            <div className='countdown-item'>
              <span>{timeLeft.days}</span> Days
            </div>
            <div className='countdown-item'>
              <span>{timeLeft.hours}</span> Hours
            </div>
            <div className='countdown-item'>
              <span>{timeLeft.minutes}</span> Minutes
            </div>
            <div className='countdown-item'>
              <span>{timeLeft.seconds}</span> Seconds
            </div>
          </div>
        </div>
  
        <style>
          {`
            @media (max-width: 768px) {
              .countdown-timer {
                font-size: 1.1em; /* Shrink font size for medium screens */
              }
            }
  
            @media (max-width: 480px) {
              .countdown-timer {
                font-size: 0.9em; /* Shrink font size for smaller screens */
              }
            }
          `}
        </style>
      </>
    );
};