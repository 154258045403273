
import { makeStyles } from '@mui/styles';
import React from 'react';
import { YesNoRadio } from '../YesNoRadio';
import { RSVPFormField } from '../../../domain/RSVPFormField';
import { NavButtonsPanel } from '../NavButtonsPanel';
import { FormPanelProps } from '../RSVPForm';
import { YesNo } from '../../../domain/YesNo';

const useStyles = makeStyles(() => ({
    label: {
        display: 'block', 
        marginBottom: '10px',
        textAlign: 'center',
    },
    textfield: { 
        marginLeft: '10px', 
        padding: '5px', 
        width: '-webkit-fill-available',
    },
    button: { 
        padding: '10px 20px', 
        backgroundColor: '#444', 
        color: '#fff', 
        border: 'none', 
        cursor: 'pointer' 
    },
    disabledButton: { 
        border: '1px solid #999999',
        backgroundColor: '#cccccc',
        color: '#666666',
        cursor: 'not-allowed' ,
    },
    attendingRadio: {
        display: 'inline-block',
        width: 'auto',
    }
}));

export const WillYouAttendScreen = (props: FormPanelProps) => {
    return (
        <div>
            <YesNoRadio
                id={RSVPFormField.attending}
                label={'Will you attend?'}
                onChange={props.onChange}
                value={props.getFormValue<YesNo>(RSVPFormField.attending)}
            />
            <NavButtonsPanel
                currentStep={props.currentStep}
                maximumStep={props.getFormValue<string>(RSVPFormField.attending) === YesNo.YES ? props.maximumStep : props.currentStep}
                hideSubmit={props.getFormValue<string>(RSVPFormField.attending) === undefined}
                onPrevStep={props.onPrevStep}
                onNextStep={props.onNextStep}
                onSubmit={props.onSubmit}
            />
        </div>
    );
};