import React from 'react';
import { makeStyles } from '@mui/styles';
import { QuestionItem } from './QuestionItem';
import { QuestionConfig } from './QuestionsConfig';

const useStyles = makeStyles(() => ({
    questions: {
        height: 'auto',
        marginBottom: 200,
    }
}));

export const Questions = () => {
    const classes = useStyles();
    return (
        <section className={classes.questions}>
            <h3>{'Questions'}</h3>
            {
                QuestionConfig.map(item => (
                    <QuestionItem 
                        question={item.question} 
                        answer={item.answer}
                        key={item.question}
                    />
                ))
            }
            <div style={{padding: 30, margin: 10, borderRadius: 10, backgroundColor: '#3e3e3f'}}>
                {'Final Note: Once we receive your RSVP, we will be sending a hardcopy invitation to your provided address. We are so excited to see you.'}
            </div>
        </section>
    );
};