import { YesNo } from '../domain/YesNo';
import { OAuthRSVPLoginObject } from '../components/rsvp/OAuthLoginPanel';
import { RSVPFormField } from '../domain/RSVPFormField';
import { Map } from 'immutable';
import React from 'react';
import { LocalStorageKeys } from '../domain/LocalStorageKeys';

enum LoadingApis {
    guestname = 'guestname',
    rsvp = 'rsvp',
}

export const useRSVPForm = () => {
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [loginObject, setLoginObject] = React.useState<OAuthRSVPLoginObject | null>(null);
    
    const [isLoadingMap, setIsLoadingMap] = React.useState(Map<LoadingApis, boolean>());
    const toggleLoading = (loadingApi: LoadingApis, value: boolean) => {
        setIsLoadingMap(isLoadingMap.set(loadingApi, value));
    };
    const [formData, setFormData] = React.useState(Map<RSVPFormField, string | object>());

    const params = new URL(window.location.href).searchParams;
    const paramsGuestName = params.get('invited');

    const [displayName, setDisplayName] = React.useState(null);

    React.useEffect(() => {
        toggleLoading(LoadingApis.guestname, true);
        fetch(`https://3jj6aid0e9.execute-api.us-east-1.amazonaws.com/prod/invite?userId=${paramsGuestName}`)
            .then(r => r.json())
            .then(data => {
                if (data) {
                    setDisplayName(data.firstName);
                    localStorage.setItem(LocalStorageKeys.pdaBoracayGuestName, data.firstName);
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                toggleLoading(LoadingApis.guestname, false);
            });
    }, []);
    

    const onMultiChange = (toMerge: Map<RSVPFormField, string | object>) => {
        setFormData(formData.merge(toMerge));
    };

    const onChange = <T extends object | string>(id: RSVPFormField, value: T) => {
        onMultiChange(Map([[id, value]]));
    };

    const onChangeEvent = (id: RSVPFormField) => (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(id, event.target.value);
    };

    const buildRSVPRequest = (formData: Map<RSVPFormField, string | object>, loginObject: OAuthRSVPLoginObject | null) => {
        return {
            ...loginObject,
            ...formData.toJS(),
            isAttending: formData.get(RSVPFormField.attending) === YesNo.YES,
            hasFoodRestrictions: formData.get(RSVPFormField.hasFoodRestrictions) === YesNo.YES,
            provider: loginObject === null ? 'manual' : loginObject.provider,
        };
    };

    const onSubmit = async () => {
        toggleLoading(LoadingApis.rsvp, true);
        await new Promise((res) => setTimeout(res, 1000));
        fetch('https://zwpa0q87rj.execute-api.us-east-1.amazonaws.com/production/rsvp', {
            method: 'PUT',
            body: JSON.stringify(buildRSVPRequest(formData, loginObject))
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setIsSubmitted(true);
        })
        .finally(() => {
            toggleLoading(LoadingApis.rsvp, false);
        });
    };

    const getFormValue = <T>(id: RSVPFormField) => {
        return formData.get(id) as T;
    };

    const onLoginOAuth = (loginObject: OAuthRSVPLoginObject) => {
        setLoginObject(loginObject);
        const name = `${loginObject.firstName} ${loginObject.lastName}`;
        const email = loginObject.email ?? '';
        onMultiChange(
            Map<RSVPFormField, string>([
                [RSVPFormField.name, name],
                [RSVPFormField.email, email],
            ])
        );
    };

    return {
        isLoading: Array.from(isLoadingMap.values()).reduce((acc, isLoading) => acc || isLoading, false),
        isSubmitted,
        displayName: displayName ?? localStorage.getItem(LocalStorageKeys.pdaBoracayGuestName),
        onChangeEvent,
        onChange,
        onSubmit,
        getFormValue,
        onLoginOAuth,
    };
};
