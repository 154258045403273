import React from 'react';
import { DetailStory } from './DetailStory';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { CoupleTimeline } from './CoupleTimeline';
import { CouplePage } from '../../domain/CouplePage';
import { AboutCoupleConfig } from './AboutCoupleConfig';
import { NYCWeddingPhotos } from './NYCWeddingPhotos';
import { ManilaProposalPhotos } from './ManilaProposalPhotos';

export const AboutCouple = () => {
    const navigate = useNavigate();
    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        currentDetailScreen: CouplePage | undefined,
    ) => {
        if (currentDetailScreen) {
            navigate(currentDetailScreen);
        }
    };
    const params = useParams();
    const screen = params['*'];
    return (
        <div>
            <div style={{
                textAlign: 'center',
            }}>
                <ToggleButtonGroup
                    color={'standard'}
                    value={screen}
                    exclusive
                    onChange={handleChange}
                    style={{backgroundColor: '#ffffff'}}
                >
                    {
                        AboutCoupleConfig.map(detail => <ToggleButton value={detail.value} key={detail.value}>{detail.title}</ToggleButton>)
                    }
                </ToggleButtonGroup>
            </div>
            <Routes>
                <Route path={CouplePage.Story} element={<DetailStory/>}/>
                <Route path={CouplePage.Timeline} element={<CoupleTimeline/>}/>
                <Route path={CouplePage.Photos} element={<NYCWeddingPhotos/>}/>
                <Route path={CouplePage.Proposal} element={<ManilaProposalPhotos/>}/>
                <Route path={'*'} element={<Navigate to={CouplePage.Story}/>}/>
            </Routes>
        </div>
    );
};