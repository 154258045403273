
import { makeStyles } from '@mui/styles';
import React from 'react';
import { RSVPFormField } from '../../../domain/RSVPFormField';
import { NavButtonsPanel } from '../NavButtonsPanel';
import { Companion } from '../../../domain/Companion';
import { FormPanelProps } from '../RSVPForm';

const useStyles = makeStyles(() => ({
    label: {
        display: 'block', 
        marginBottom: '10px',
        textAlign: 'center',
    },
    textfield: { 
        marginLeft: '10px', 
        padding: '5px', 
        width: '-webkit-fill-available',
    },
}));

export const RSVPCompanionsScreen = (props: FormPanelProps) => {
    const classes = useStyles();
    const companions: Companion[] = props.getFormValue<Companion[]>(RSVPFormField.companions) ?? [];

    const [isFoodRestrictionShown, setIsFoodRestrictionShown] = React.useState<boolean[]>([]);

    return (
        <div>
            {
                companions.length === 0 ?
                <div style={{textAlign: 'center', margin: 10}}>
                    {'Add invited people to your RSVP by clicking the button below!'}
                </div>
                :
                companions.map((value, index) => (
                    <div style={{display: 'flex'}}>
                        <div>
                            <label className={classes.label} style={{display: 'inline-block'}}>
                                <input 
                                    type={'text'} 
                                    name={RSVPFormField.email} 
                                    style={{width: 150}}
                                    className={classes.textfield} 
                                    value={companions[index].name ?? ''}
                                    placeholder={`name`}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const companions = props.getFormValue<Companion[]>(RSVPFormField.companions) ?? [];
                                        const allergies = companions[index]?.allergies ?? '';
                                        const updatedCompanions = [...companions.slice(0, index), {name: event.target.value, allergies}, ...companions.slice(index + 1)];
                                        props.onChange(RSVPFormField.companions, updatedCompanions);
                                    }} 
                                />
                            </label>
                        </div>
                        <div>
                            <label className={classes.label} style={{display: 'inline-block'}}>
                                {
                                    !!isFoodRestrictionShown[index] ? 
                                        <input 
                                            type={'text'} 
                                            name={RSVPFormField.email} 
                                            style={{width: 100}}
                                            className={classes.textfield} 
                                            value={companions[index].allergies ?? ''}
                                            placeholder={`food restriction`}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                const companions = props.getFormValue<Companion[]>(RSVPFormField.companions) ?? [];
                                                const name = companions[index]?.name ?? '';
                                                const updatedCompanions = [...companions.slice(0, index), {name, allergies: event.target.value}, ...companions.slice(index + 1)];
                                                props.onChange(RSVPFormField.companions, updatedCompanions);
                                            }} 
                                        />
                                        :
                                        <button
                                            style={{ 
                                                marginTop: 10, 
                                                marginLeft: 10, 
                                                padding: 5, 
                                                fontSize: 10, 
                                                backgroundColor: '#4e4e4e',
                                            }}
                                            onClick={() => {
                                                setIsFoodRestrictionShown([
                                                    ...isFoodRestrictionShown.slice(0, index),
                                                    true,
                                                    ...isFoodRestrictionShown.slice(index + 1),
                                                ])
                                            }}
                                        >
                                            {'Add Food Restriction'}
                                        </button>
                                }
                            </label>
                        </div>
                        <div style={{marginTop: 8, marginLeft: 8}}>
                            <button
                                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    event.preventDefault();
                                    const companions = props.getFormValue<Companion[]>(RSVPFormField.companions) ?? [];
                                    const updatedCompanions = [...companions.slice(0, index), ...companions.slice(index + 1)];

                                    const updatedShowFoodRestriction = [...isFoodRestrictionShown.slice(0, index), ...isFoodRestrictionShown.slice(index + 1)];
                                    setIsFoodRestrictionShown(updatedShowFoodRestriction);

                                    props.onChange(RSVPFormField.companions, updatedCompanions);
                                }}
                                style={{
                                    backgroundColor: '#4e4e4e',
                                    padding: 5, 
                                    fontSize: 10, 
                                }}
                            >
                                {'(-) Remove guest'}
                            </button>
                        </div>
                    </div>
                ))
            }
            <div style={{textAlign: 'center', marginBottom: 10}}>
                <button
                    style={{
                        backgroundColor: '#ec7063',
                        padding: 10,
                    }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.preventDefault();
                        const companions = props.getFormValue<Companion[]>(RSVPFormField.companions) ?? [];
                        setIsFoodRestrictionShown([...isFoodRestrictionShown, false])
                        props.onChange(RSVPFormField.companions, [...companions, {name: '', allergies: ''}]);
                    }}
                >
                    {'(+) Add Another Invited Guest'}
                </button>
            </div>
            <NavButtonsPanel
                currentStep={props.currentStep}
                maximumStep={props.maximumStep}
                onPrevStep={props.onPrevStep}
                onNextStep={props.onNextStep}
                onSubmit={props.onSubmit}
            />
        </div>
    );
};