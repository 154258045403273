
import React from 'react';
import { OAuthLoginPanel, OAuthRSVPLoginObject } from '../OAuthLoginPanel';
import { FormPanelProps } from '../RSVPForm';

export const MethodOfRSVPScreen = (props: FormPanelProps) => {
    return (
        <div>
            <div style={{textAlign: 'center', marginBottom: 10}}>
                {'If your family member / group already listed you as a companion, please do not fill this out! You are already RSVPed on their confirmation e-mail.'}
            </div>

            <div style={{width: 200, margin: 'auto'}}>
                <div style={{ margin: '0 5px'}}>
                    <button 
                        onClick={props.onNextStep}
                        style={{ fontSize: 12, height: 40, width: '100%', borderRadius: 5, textAlign: 'left' }}
                    >
                        <span style={{marginLeft: -4}}>{'✉️'}</span>
                        <span style={{marginLeft: 12}}>{'RSVP manually'}</span>
                    </button>
                </div>
                <div style={{textAlign: 'center'}}>
                    {'or'}
                </div>
                <OAuthLoginPanel
                    onLoginOAuth={(loginObject: OAuthRSVPLoginObject) => {
                        props.onNextStep({ preventDefault: () => {} } as any);
                        props.onLoginOAuth(loginObject);
                    }}
                />
            </div>
        </div>
    );
};