
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { RSVPForm } from './RSVPForm';
import { Countdown } from './Countdown';
// import { ImageAutomaticCarousel } from '../ImageAutomaticCarousel';

const useStyles = makeStyles(() => ({
  rsvpContainer: { 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    backgroundColor: '#2c2c2e',
    borderRadius: 12,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
  },
  rsvpForm: { 
    flex: '1', 
    display: 'flex', 
    justifyContent: 'center', 
    flexDirection: 'column' 
  },
}));

const images = [
  '/images/DSC00629.jpg',
  '/images/DSC01536.jpg',
  '/images/DSC09688.jpg'
];


const RSVP: React.FC = () => {
  const classes = useStyles();
  const [currentImage, setCurrentImage] = useState(0);
  const onInterval = () => {
    setCurrentImage((prevImage) => (prevImage === images.length - 1 ? 0 : prevImage + 1));
  };

  return (
    <section className={'rsvp-section'}>
      <h3>{'RSVP'}</h3>
      <p>{'We would love to know if you can join us. Please fill out the form below to let us know!'}</p>
      <Countdown
        onInterval={onInterval}
      />
      <div className={classes.rsvpContainer}>
        <div className={classes.rsvpForm}>
          <RSVPForm/>
        </div>
      </div>
    </section>
  );
};



export default RSVP;
