
import { makeStyles } from '@mui/styles';
import React from 'react';
import { RSVPFormField } from '../../../domain/RSVPFormField';
import { NavButtonsPanel } from '../NavButtonsPanel';
import { FormPanelProps } from '../RSVPForm';
import { StringUtils } from '../../../utils/StringUtils';

const useStyles = makeStyles(() => ({
    label: {
        display: 'block', 
        marginBottom: '10px',
        textAlign: 'center',
    },
    textfield: { 
        marginLeft: '10px', 
        padding: '5px', 
        width: '-webkit-fill-available',
    },
}));

export const WhoAreYouScreen = (props: FormPanelProps) => {
    const classes = useStyles();
    const name = props.getFormValue<string>(RSVPFormField.name);
    const email = props.getFormValue<string>(RSVPFormField.email);
    const isNextDisabled = StringUtils.isEmpty(name) || StringUtils.isEmpty(email);
    return (
        <div>
            <label className={classes.label}>
                {'Please confirm your name:'}
                <input 
                    type={'text'} 
                    name={RSVPFormField.name} 
                    className={classes.textfield} 
                    value={name}
                    onChange={props.onChangeEvent(RSVPFormField.name)} 
                />
            </label>
            <label className={classes.label}>
                {'Also your email:'}
                <input 
                    type={'email'} 
                    name={RSVPFormField.email} 
                    className={classes.textfield} 
                    value={email}
                    onChange={props.onChangeEvent(RSVPFormField.email)} 
                />
            </label>
            <NavButtonsPanel
                currentStep={props.currentStep}
                maximumStep={props.maximumStep}
                onPrevStep={props.onPrevStep}
                onNextStep={props.onNextStep}
                onSubmit={props.onSubmit}
                isNextDisabled={isNextDisabled}
            />
        </div>
    );
};