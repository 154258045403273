import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { CoupleTimelineConfig, TimelineConfig } from './CoupleTimelineConfig';

interface CustomizedTimelineProps {
    config: TimelineConfig[];
}

const colors = [
    'primary',
    'secondary',
    'error', 
    'success',
    'warning',
];

const CustomizedTimeline = (props: CustomizedTimelineProps) => {
    return (
        <Timeline position="alternate">
            {
                props.config.map((item, index) => (
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0', color: '#1976d2', fontWeight: 'bold', fontSize: 20 }}
                            variant="body2"
                            color="text.secondary"
                        >
                            {item.date}
                        </TimelineOppositeContent>

                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color={colors[index % 5] as any} >
                                <item.Icon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>

                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span">
                                {item.title}
                            </Typography>
                            <Typography>{item.description}</Typography>
                        </TimelineContent>
                    </TimelineItem>
                ))
            }
        </Timeline>
    );
};

export const CoupleTimeline = () => {
    return (
        <section>
            <h3>{'Timeline'}</h3>
            <div>
                <CustomizedTimeline config={CoupleTimelineConfig}/>
            </div>
        </section>
    );
};